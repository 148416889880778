import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmRegistration } from '../actions';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import './confirm-registration.scss';
import analytics from '../../util/analytics';

const mapDispatchToProps = dispatch => ({
  confirm: (authCode, id) => dispatch(confirmRegistration(authCode, id))
});

class ConfirmRegistration extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dots: 0
    };
    this.confirm = this.confirm.bind(this);
  }
  componentDidMount () {
    this.confirm();
  }
  confirm () {
    const authCode = this.props.match.params.authCode;
    const id = this.props.match.params.id;
    this.props.confirm(authCode, id);
    analytics(
      'send',
      'event',
      'Signup',
      'emailConfirmed',
      id
    );
  }
  render () {
    return (
      <div className="confirm-registration">
        <h4 className='title'><span>Authorising your account</span></h4>
        <LoadingIcon />
      </div>
    );
  }
}

ConfirmRegistration.propTypes = {
  confirm: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      authCode: PropTypes.string,
      id: PropTypes.string
    })
  })
};

export default connect(null, mapDispatchToProps)(ConfirmRegistration);
